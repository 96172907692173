
import { Component, Vue, Prop } from 'vue-property-decorator';
import RatesDemandMapPopup from '@/modules/rates/march-demo/rates-demand-map/rates-demand-map.popup.vue';

@Component({
    components: { RatesDemandMapPopup },
})
export default class RatesDemandMapModal extends Vue {
    @Prop({ required: false })
    private day?: string;
}
